import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

// User visits site
//
// JS checks for cookie, if none, hits API, receives 'true' (user can access)
// OR 'false' user _can't_ access.
//
// If true, cookie is set, site is loaded.
//
// If false, user is forwarded to /auth
//
// User auths, and page is loaded adding a cookie to the browser
// User redirected to home page.
const Auth = () => {
  useEffect(() => {
    const storageKey = 'Dx9BfmBb-ZBKrQmjkn';
    // If Basic Auth succeeds, set a cookie so we don't redirect back to here on the home page.
    localStorage.setItem(storageKey, true);
    // Redirect to the home page.
    navigate('/');
  }, []);

  return (
    <h1 style={{ fontSize: '3rem', color: 'white', padding: '100px' }}>
      Redirecting...
    </h1>
  );
};

export default Auth;
